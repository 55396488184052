import api from '@/command/api'
import orderConfig from '@/orderConfig'
import { getAction } from '@/command/netTool'

export const applicationFormMixin = {
  methods: {
    // 获取选中订单详情
    getDetailApplicationForm(orderId, orderItemId) {
      return new Promise((resolve, reject) => {
        getAction(`/api/farmActivityUser/detail?id=${orderId}`)
          .then((result) => {
            if (result.data.regMethod == '2' && result.data.refundStatus === '1') {
              getAction(`/api/farmActivityUser/getOrderDetail?orderId=${result.data.orderId}`)
                .then((res) => {
                  result.data.refundList = res.data.refundList
                  resolve(result.data)
                })
                .catch((e) => resolve(result.data))
            } else {
              resolve(result.data)
            }
          })
          .catch((e) => reject && reject(e))
      })
    },

    onRowSelect(parent, orderItemId, isDetail) {
      const ext = [
        // 表格
        orderConfig.tableEvents,
        // 基础信息
        orderConfig.baseEvents,
        // 退款申请
        orderConfig.retuenInfo,
        // 记录
        orderConfig.recordTicket,
      ]
      const onDetail = (e) => {
        orderConfig.open({
          title: '报名信息',
          data: e,
          params: {
            data: { ...e },
            // customOrderId: parent.id,
            // customOrderItemId: orderItemId,
          },
          executeFun: ext,
          onHidden: () => {
            this.getInit()
          },
          foot: ({ close, submit }) => {
            return [
              {
                name: '取消',
                onClick: close,
              },
            ]
          },
        })
      }
      this.getDetailApplicationForm(parent.id, orderItemId).then(onDetail)
    },
  },
}
