<script>
import moment from 'moment'
import api from '@/command/api'
import apiTool from '@/command/apiTool'
import DrawerForm from '@/components/DrawerForm'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import QrCode from '../../../components/QrCode'
import { orderStateList4 } from '@/utils/textFile'
import { putAction } from '@/command/netTool'
import { applicationFormMixin } from '@/mixins/orderProcessing/applicationForm'

export default {
  name: 'toSignUp',
  data() {
    return {
      ...api.command.getState(),
    }
  },
  mixins: [applicationFormMixin],
  mounted() {
    this.getDataInit()
  },
  methods: {
    getDataInit() {
      const { id } = this.$route.query
      api.command.getList.call(this, {
        url: '/farmActivityUser/page',
        current: 1,
        paramsValue: {
          activityId: id,
        },
      })
    },
    getHeader() {
      return []
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          type: 'lt-120',
          sorter: (a, b) => a.id - b.id,
        },
        {
          dataIndex: 'nickname',
          title: '用户',
          type: 'lt-100',
          customRender: function (text, records) {
            return (
              <div class="product-order-item">
                <img src={records.headImg} />
                <div>{records.nickname}</div>
              </div>
            )
          },
          onExport: (text, records) => {
            return `头像${records.headImg} 昵称${records.nickname}`
          },
          sorter: (a, b) => a.nickname.length - b.nickname.length,
        },
        {
          dataIndex: 'phone',
          title: '手机号',
          sorter: (a, b) => a.phone - b.phone,
        },
        // {
        //   dataIndex: 'showType',
        //   title: '报名来源',
        //   align: 'left',
        //   filters: [
        //     {
        //       text: '首页',
        //       value: '1',
        //     },
        //     {
        //       text: '商城',
        //       value: '2',
        //     },
        //   ],
        //   filterMultiple: false,
        // },
        {
          dataIndex: 'createTime',
          title: '报名时间',
          align: 'center',
          sorter: (a, b) => moment(a.createTime).diff(moment(b.createTime)),
        },
        {
          dataIndex: 'payAmount',
          title: '报名费用',
          align: 'left',
          sorter: (a, b) => a.payAmount.length - b.payAmount.length,
          customRender: function (text, records) {
            return <div class="product-order-item-copy">{text || 0}</div>
          },
        },
        {
          dataIndex: 'orderStatus',
          title: '订单状态',
          type: 'badge',
          filters: orderStateList4,
          filterMultiple: false,
          render(data, records) {
            return {
              // showDot: true,
              name: records.refundId ? '待退款' : (orderStateList4.find((e) => e.value == data) || { text: '' }).text,
              color: records.refundId ? 'red' : 'transparent',
            }
          },
          onExport: (text) => (orderStateList4.find((e) => e.value == text) || { text: '' }).text,
        },
        {
          dataIndex: 'a8',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          typeData: ({ records }) => {
            return [
              {
                type: 'pop',
                popTitle: '确认同意退款吗?',
                name: '同意',
                display: records.refundStatus == 1,
                onClick: () => {
                  let params = {
                    orderId: records.orderId,
                    refundId: records.refundId,
                    handleResult: '0',
                  }
                  let url = '/farmActivityUser/refund'
                  putAction(url, {
                    ...params,
                  }).then((result) => {
                    if (result.code == 200) {
                      if (result.data != 5) {
                        this.$message.success('操作成功')
                      } else {
                        this.$message.error('操作失败')
                      }
                      this.getDataInit()
                    } else {
                      this.$message.error(result.msg)
                    }
                  })
                },
              },
              {
                name: '拒绝',
                display: records.refundStatus == 1,
                onClick: () => {
                  this.handlePropup(records)
                },
              },
              {
                type: 'pop',
                popTitle: '确认关闭吗?',
                name: '关闭订单',
                display: records.orderStatus == '0',
                onClick: () => this.onBatchDelete([records]),
              },
              {
                name: '查看',
                display: true,
                onClick: () => this.onRowSelect(records, records.id),
              },
            ].filter((e) => (e.display == undefined ? true : e.display))
          },
        },
      ]
    },
    // 批量删除
    onBatchDelete(keys) {
      return api.order.batchClose.call(this, {
        url: '/common/order/closeBatch',
        params: { idList: keys.map((e) => e.orderId) },
      })
    },
    handlePropupData(source, formData) {
      const form = [
        {
          title: '基本信息',
          form: [
            {
              name: '报名人',
              type: 'text',
              key: 'nickname',
              cols: 12,
            },
            {
              name: '报名来源：',
              key: '',
              type: 'text',
              cols: 12,
            },
            {
              name: '手机号',
              key: 'phone',
              type: 'text',
              cols: 12,
            },
          ],
        },
      ]
      return form
    },
    handlePropup(records) {
      apiTool.showDrawer({
        title: '拒绝退款',
        width: '500px',
        form: [
          {
            name: '理由',
            type: 'textArea',
            key: 'content',
          },
        ],
        success: ({ data, setHidden }) => {
          if (data.content) {
            let params = {
              orderId: records.orderId,
              refundId: records.refundId,
              handleResult: '4',
              handleNote: data.content,
            }
            let url = '/farmActivityUser/refund'
            putAction(url, {
              ...params,
            }).then((result) => {
              if (result.code == 200) {
                if (result.data != 5) {
                  this.$message.success('操作成功')
                } else {
                  this.$message.error('操作失败')
                }
                this.getDataInit()
                setHidden()
              } else {
                this.$message.error(result.msg)
              }
            })
          } else {
            this.$message.warning('请填写拒绝理由！')
          }
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '返回',
              onClick: close,
            },
            {
              name: '确认',
              type: 'primary',
              onClick: submit,
            },
          ]
        },
      })
    },
    getButton() {
      return []
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        // headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  },
}
</script>
<style scoped lang="less">
.product-order-item {
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}
</style>
